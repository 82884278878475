
export default {
  mixins: [],
  props: [],
  data() {
    return {
      displayErstellenNavigation: false,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start() {
      this.displayErstellenNavigation = true;
      this.$nuxt.$emit('showBackdrop');
    },
    reset() {
      this.resetForm();
      this.$nuxt.$emit('hideBackdrop');
    },
    resetForm() {
      this.displayErstellenNavigation = false;
    }
  },
  mounted() {
    this.$nuxt.$on('erstellen', this.start);
    this.$nuxt.$on('backdropClick', this.reset);
    this.$nuxt.$on('routeChanged', this.reset);
    this.$nuxt.$on('suchfunktionSuchen', this.resetForm);
  },
  beforeDestroy() {
    this.$nuxt.$off('erstellen', this.start)
    this.$nuxt.$off('backdropClick', this.reset);
    this.$nuxt.$off('routeChanged', this.resetForm);
  }

}
