import { render, staticRenderFns } from "./MitarbeiterFormular.vue?vue&type=template&id=b28e1da0"
import script from "./MitarbeiterFormular.vue?vue&type=script&lang=js"
export * from "./MitarbeiterFormular.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalsDetailsMitarbeiterFormular: require('/builds/logo-yes/portal2022/frontend/components/modals/details/MitarbeiterFormular.vue').default})
