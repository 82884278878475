
export default {
  data() {
    return {
      suchbegriff: '',
      filterModel: null,
      filterModels: ['Mitarbeiter', 'Arzt', 'Patient', 'Patientenkontakt', 'Krankenkasse'],
      displaySuchfunktion: false,
    }
  },
  computed: {},
  methods: {
    focus() {
      this.$nuxt.$emit('showBackdrop');
      this.$nextTick(() => {
        if (this.$refs.suchfeld) this.$refs.suchfeld.focus()
        else {
          setTimeout(() => {
            if (this.$refs.suchfeld) this.$refs.suchfeld.focus()
          }, 100);
        }
      });
    },
    filtern(model) {
      this.filterModel = model;
      let filter = [];
      if (this.filterModel) filter.push({where: ['model_type', 'App\\Models\\'+model]});
      this.$$fill('kontakte', {width: ['model'], filter: filter, perPage: 10}, true);
      this.suchfunktionSuchen();
    },
    suchfunktionSuchen() {
      this.$$suchen('kontakte', this.suchbegriff)
    },
    globalSuchen(suchbegriff) {
      this.suchbegriff = suchbegriff;
      this.focus();
      this.suchfunktionSuchen();
      this.displaySuchfunktion = true;
    },
    reset() {
      this.resetForm();
      this.$nuxt.$emit('hideBackdrop');
    },
    resetForm() {
      this.api.kontakte.response = null;
      this.suchbegriff = '';
      this.displaySuchfunktion = false;
    }
  },
  mounted() {
    this.filtern(null);
    this.$nuxt.$on('backdropClick', this.reset);
    this.$nuxt.$on('routeChanged', this.reset);
    this.$nuxt.$on('suchfunktionSuchen', this.globalSuchen);
    this.$nuxt.$on('delete-arzt', this.suchfunktionSuchen);
    this.$nuxt.$on('update-kontakt', this.suchfunktionSuchen);
    this.$nuxt.$on('erstellen', this.resetForm);
  },
  beforeDestroy() {
    this.$nuxt.$off('backdropClick', this.reset);
    this.$nuxt.$off('routeChanged', this.reset);
    this.$nuxt.$off('suchfunktionSuchen', this.globalSuchen);
    this.$nuxt.$off('delete-arzt', this.suchfunktionSuchen);
    this.$nuxt.$off('update-kontakt', this.suchfunktionSuchen);
    this.$nuxt.$off('erstellen', this.resetForm);
  }
}
