
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'config-formular',
      modaltitel: 'Config',
      event: 'edit-config',
      config: null,
      edit: false,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(config) {
      if (config) {
        this.config = config;
        this.edit = true;
        if (typeof this.config.value === 'object') {
          this.config.value = JSON.stringify(this.config.value);
        }

      }
      else {
        this.config = {key: '', value: ''}
        this.edit = false;
      }
      this.$bvModal.show(this.modalid);
    },
    speichern() {
      if (this.edit) {
        this.$$update('config', this.config, 'Erfolgreich gespeichert', '/api/config/'+this.config.key).then(response => {
          this.$nuxt.$emit('update-config');
          this.$bvModal.hide(this.modalid);
        })
      }
      else {
        this.$$store('config', this.config, 'Erfolgreich gespeichert').then(response => {
          this.$nuxt.$emit('update-config');
          this.$bvModal.hide(this.modalid);
        })
      }
    }
  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
