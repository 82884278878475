
import formularmodal from '~/mixins/formulare/formularmodal';
export default {
  mixins: [formularmodal],
  props: [],
  data() {
    return {
      modal: {
        id: 'profilbild-modal',
        event: 'create::profilbild',
        titel: null,
        modelname: 'Profilbild',
        model: 'profibilder'
      },
      image: null,
      refreshkey: 0,
    }
  },
  computed: {},
  watch: {},
  methods: {
    speichern() {
      if (!this.image || !this.model) return;
      this.$$upload('profilbilder', {kontakt_id: this.model.id}, this.image, null, 'Profibild aktualisiert', '/api/kontakte/'+this.model.id+'/profilbild').then((response) => {
        this.model = response;
        this.refreshkey++;
      });
    },
    changeFile(file) {
      this.image = file;
    },
  },

}
